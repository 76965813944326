import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/fleet/67-80-seat-double-decker.jpg"
import interiorImg from "../../images/fleet/67-80-seat-double-decker-interior.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="74 seat double decker bus hire" description="View our 74 seat double decker bus. Double decker hire for up to 74 passengers with Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Double decker hire</h1>
            </div>

            <div className="news-article-body">
              <p className="news-article-img">
                <img src={mainImg} alt="Double decker hire" />
                <img src={interiorImg} alt="Double decker interior" />
              </p>
              <ul>
                <li>74 Seat</li>
                <li>No seatbelts</li>
                <li>Wheelchair accessible - subject to availability, please request when booking</li>
                <li>CCTV – internal and external</li>
                <li>GPS tracking</li>
              </ul>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
              <p className="fleet-smallprint">Please note due to the different vehicle makes and models we have among our fleet of 60 the vehicle sent may differ from the one pictured.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
